<template>
  <div class="seckill">
    <div class="seckill-head">
      <div class="seckill-head-l">
        <el-button type="primary" @click="editGoods({}, 0)">新建活动</el-button>
      </div>
      <div class="seckill-head-r">
        <!-- <el-input placeholder="请输入内容" v-model="input2">
          <template slot="append"><i class="el-icon-search"></i></template>
        </el-input> -->
      </div>
    </div>

    <div class="seckill-box">
      <el-tabs v-model="activeName" @tab-click="handleClick" v-if="0">
        <el-tab-pane label="所有活动" name="first"></el-tab-pane>
        <el-tab-pane label="未开始" name="second"></el-tab-pane>
        <el-tab-pane label="进行中" name="third"></el-tab-pane>
        <el-tab-pane label="已结束" name="fourth"></el-tab-pane>
      </el-tabs>

      <el-table
        :data="tableData"
        :border="$table('border')"
        :stripe="$table('stripe')"
        :header-cell-style="{ background: $table('bgcolor') }"
        style="width: 100%"
      >
        <el-table-column label="活动编号" :width="$table('width')">
          <template slot-scope="scope">
            <span v-if="pages[0].page < 2">
              {{ scope.$index + 1 }}
            </span>
            <span v-else>
              {{ scope.$index + 1 + pages[0].page * 15 - 15 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="活动名称"> </el-table-column>
        <el-table-column prop="begin_time" label="活动时间"> </el-table-column>
        <!-- <el-table-column prop="province" label="适用门店"> </el-table-column> -->
        <el-table-column prop="status" label="活动状态">
          <template slot-scope="scope">
            {{ scope.status ? "进行中" : "已结束" }}
          </template>
        </el-table-column>
        <el-table-column prop="order_amount" label="订单实付金额（元）">
        </el-table-column>
        <el-table-column prop="order_count" label="付款订单数">
        </el-table-column>
        <!-- <el-table-column prop="zip" label="付款人数"> </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="btn-box">
              <el-button size="mini" @click="editGoods(scope, 1)"
                >编辑</el-button
              >
              <el-button size="mini" type="danger" @click="del_Goods(scope)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="$pageStatus(pages[0].total)[0]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="$pageStatus(pages[0].total)[1]"
        layout="total, prev, pager, next, jumper"
        :total="pages[0].total"
      >
      </el-pagination>
    </div>
    <!-- 添加活动 -->
    <el-dialog title="商品设置" :visible.sync="dialogVisible" width="80%">
      <div class="seckill-alert">
        <div>
          <el-alert
            :closable="false"
            title="选择商品（只能添加一件商品参与特价秒杀活动）"
            type="info"
          >
          </el-alert>
          <el-table
            :data="from.shop"
            :border="$table('border')"
            :stripe="$table('stripe')"
            :header-cell-style="{ background: $table('bgcolor') }"
            style="width: 100%"
          >
            <el-table-column prop="name" label="商品名">
              <template slot-scope="scope">
                {{ scope.row.name ? scope.row.name : shopData.name }}
              </template>
            </el-table-column>
            <el-table-column prop="o_price" label="商品原价">
              <!-- <template slot-scope="scope">{{scope.row}}</template> -->
              <template>
                {{ shopData.o_price || from.o_price }}
              </template>
            </el-table-column>
            <el-table-column prop="price" label="秒杀价格">
              <!-- <template >{{from.price}}</template> -->
            </el-table-column>
            <el-table-column prop="stock" label="秒杀库存">
              <!-- <template >{{from.stock}}</template> -->
            </el-table-column>
            <el-table-column label="编辑">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
                <el-button type="text" size="small" @click="save_shop(shopData)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="seckill-alert-add">
            <el-button type="primary" @click="add_shop">添加商品</el-button>
          </div>
        </div>
        <div>
          <el-alert :closable="false" title="活动设置" type="info"> </el-alert>
          <div class="seckill-alert-list">
            <span>活动名称：</span>
            <el-input
              style="width:50%;"
              v-model="from.title"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="seckill-alert-list">
            <span>开始时间：</span>
            <el-date-picker
              style="width:50%;"
              v-model="from.begin_time"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <div class="seckill-alert-list">
            <span>结束时间：</span>
            <el-date-picker
              style="width:50%;"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="from.end_time"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <div class="seckill-alert-list" v-if="0">
            <span>适用店铺：</span>
            <el-select
              v-model="from.shop"
              placeholder="请选择"
              style="width:50%;"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="seckill-alert-list">
            <span>每人限购：</span>
            <div class="seckill-alert-list-item">
              <el-checkbox v-model="from.status">开启限购</el-checkbox>
              <div style="margin-left:15px;">
                每人限购可购买
                <el-input-number
                  v-model="from.limit_num"
                  :min="0"
                  :max="9999"
                  :disabled="!from.status"
                  label="描述文字"
                ></el-input-number>
                件
              </div>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save_seckill">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑商品 -->
    <el-dialog
      title="设置秒杀价格和库存"
      :visible.sync="dialogVisible1"
      width="50%"
    >
      <div class="seckill-alert">
        <div class="seckill-alert-list">
          <span>秒杀价格：</span>
          <div class="seckill-alert-list-item" style="width:80%">
            <el-input-number
              v-model="from.price"
              :precision="2"
              :step="0.1"
              :min="0.1"
              :max="9999"
              label="描述文字"
            ></el-input-number>
            <!-- <span style="margin-left:15px;"
              >原价：{{
                from.shop[0] ? from.shop[0].o_price : from.price1
              }}</span
            > -->
          </div>
        </div>
        <div class="seckill-alert-list">
          <span>秒杀库存：</span>
          <div class="seckill-alert-list-item" style="width:80%">
            <el-input-number
              v-model="from.stock"
              :min="1"
              :max="9999"
              label="描述文字"
            ></el-input-number>
          </div>
        </div>
        <el-alert
          :closable="false"
          title="秒杀库存为每个适用店铺的独立库存，比如设置10，则每个店铺的秒杀库存均为10"
          type="info"
        >
        </el-alert>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="save_shop(0)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加商品 -->
    <el-dialog title="选择服务" :visible.sync="dialogVisible2" width="80%">
      <el-tabs tab-position="top" @tab-click="handleClick4">
        <el-tab-pane
          :label="item.name"
          v-for="(item, index) in product_type"
          :key="index"
        >
          <el-table
            :data="item.list"
            :border="$table('border')"
            :stripe="$table('stripe')"
            :header-cell-style="{ background: $table('bgcolor') }"
            style="width: 100%"
          >
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="stock" label="库存">
              <template>9999999</template>
            </el-table-column>
            <el-table-column prop="stock" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="save_shop(scope.row, scope.row.price)"
                  type="text"
                  size="small"
                  >选择商品</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="currentPage4"
        :page-sizes="[20, 30, 50, 80]"
        :page-size="20"
        layout="total,  prev, pager, next, jumper"
        :hide-on-single-page="pages[1].total < 15"
        :total="pages[1].total"
      ></el-pagination>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible2 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input2: "",
      activeName: "second",
      currentPage4: 1,
      tableData: [],
      dialogVisible: false,
      options: [
        {
          value: "1",
          label: "小花豆",
        },
      ],
      value: "",
      from: {
        name: "",
        begin_time: "",
        end_time: "",
        price: "",
        price1: "",
        stock: "",
        goods_type: "",
        goods_id: "",
        shop: [],
        status: false,
        is_limit: 1,
        limit_num: "",
      },
      dialogVisible1: false,
      product: [],
      product_data: [],
      product_type: [],
      dialogVisible2: false,
      pages: [
        { page: 1, total: 1 },
        { page: 1, total: 1 },
      ],
      alertType: 0, //编辑标识
      shopData: {}, //商品数据
    };
  },
  methods: {
    handleClick() {
      this.from.shop = [];
      this.from.o_price = 0;
    },
    handleClick4(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pages[0].page = val;
      this.get_mk_list();
    },
    handleCurrentChange(val) {
      this.pages[0].page = val;
      this.get_mk_list();
      console.log(`当前页: ${val}`);
    },
    handleSizeChange1(val) {
      this.pages[1].page = val;
      this.getproducttype();
    },
    handleCurrentChange1(val) {
      this.pages[1].page = val;
      this.getproducttype();
    },
    getproducttype() {
      let that = this;
      that.$axios({
        href: "/api/app/meiye/projecttype/store",
      }).then((res) => {
        console.log('getproducttypeRes', res);
        let result = res.data;
        const len = result.length;
        for (let i = 0; i < len; i++) {
          result[i].list = [];
        }
        that.product_type = result;

        that.$axios({
          href: "/api/app/meiye/project/store",
          data: { page: that.pages[1].page },
        }).then((res) => {
          console.log('getProjectStoreRes', res);
          let result = res.data;
          const len = result.length;
          const lens = that.product_type.length;
          that.pages[1].total = result.total ? result.total : len;
          for (let i = 0; i < lens; i++) {
            for (let l = 0; l < len; l++) {
              if (result[l].category_id == that.product_type[i].id) {
                that.product_type[i].list.push(result[l]);
              }
            }
          }
          that.product_data = result;
        });
      });
    },

    get_mk_list() {
      this.$axios({
        href: "/api/app/meiye/mk/store",
        data: { page: this.pages[0].page },
      }).then((res) => {
        console.log('get_mk_listRes', res);
        if (res.data && res.data.data && res.data.data.items) {
          let d = res.data.data.items;
          const len = d.length;
          this.pages[0].total = res.data.data.total;
          for (let i = 0; i < len; i++) {
            d[i].o_price = d[i].goods_info.price;
            d[i].shop = [d[i].goods_info];
            d[i].shop[0].stock = d[i].stock;
          }
          this.tableData = d;
        }
      });
    },
    add_shop() {
      if (this.from.shop.length >= 1) {
        this.$message({
          message: "一个活动只允许添加一件商品",
          type: "warning",
        });
        return false;
      }
      this.dialogVisible2 = true;
    },
    save_shop(obj, price) {
      let arr = JSON.parse(JSON.stringify(obj));
      // arr.price1 = arr.price;
      console.log('save_shop arr', arr);
      console.log('save_shop from before', this.from);
      // console.log(arr, this.from);

      if (!obj) {
        // alert(1)
        this.dialogVisible1 = false;
        this.from.shop = [{}];
        // this.from.shop.push(this.from.shopData);
        this.from.shop[0].stock = this.from.stock;
        this.from.shop[0].price = this.from.price;
        this.from.shop[0].price = this.from.price;
        this.from.shop[0].id = this.from.id;

        this.from.goods_id = this.from.id;

        //  this.from.price1 = 111111;
      } else {
        //  alert(2)
        this.dialogVisible1 = true;
        this.from.shopData = arr;
        this.from.shop = [arr];
        this.shopData = arr;
        console.log('save_shop shopData', this.shopData);
        this.from.o_price = this.from.o_price || price;
      }
      console.log('save_shop from after', this.from)
    },
    save_seckill() {
      console.log('save_seckill from before', this.from);

      if (!this.from.shop.length) {
        this.$message({
          message: "请添加商品",
          type: "warning",
        });
        return false;
      }
      if (!this.from.title) {
        this.$message({
          message: "活动标题不能为空",
          type: "warning",
        });
        return false;
      }

      if (!this.from.begin_time || !this.from.end_time) {
        this.$message({
          message: "开始时间和结束时间不能为空",
          type: "warning",
        });
        return false;
      }

      this.from.is_limit = this.from.status ? 1 : 0;
      this.from.limit_num = this.from.status ? this.from.limit_num : 0;

      /* this.from.goods_id = this.from.shopData.id ? this.from.shopData.id : this.from.goods_id; */
      this.from.goods_type = 2;
      console.log('save_seckill from after', this.from);

      if (this.alertType) {
        this.$axios({
          href: "/api/app/meiye/mk/edit",
          data: this.from,
        }).then(() => {
          this.$message({
            message: "修改活动成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.get_mk_list();
        });
      } else {
        this.from.goods_id = this.from.shopData.id ? this.from.shopData.id : this.from.goods_id;
        this.$axios({
          href: "/api/app/meiye/mk/add",
          data: this.from,
        }).then(() => {
          this.$message({
            message: "添加活动成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.get_mk_list();
        });
      }
    },
    editGoods(obj, type) {
      console.log('editGoods obj', obj);
      console.log('editGoods type', type);
      if (type) {
        this.shopData = obj.row.goods_info;
        this.alertType = 1;
        this.from = obj.row;
        // this.from.shop = [];
        this.from.goods_info.price = obj.row.price;
        this.from.status = this.from.is_limit - 0 ? true : false;
      } else {
        this.getproducttype();
        this.alertType = 0;
        this.from = {
          name: "",
          begin_time: "",
          end_time: "",
          price: "",
          price1: "",
          stock: "",
          goods_type: "",
          goods_id: "",
          shop: [],
          status: false,
          is_limit: 1,
          limit_num: "",
        };
      }
      this.dialogVisible = true;

      console.log(obj);
    },

    del_Goods(obj) {
      this.$confirm("此操作将永久删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/mk/del",
            data: { id: obj.row.id },
          }).then(() => {
            this.$message({
              message: "删除活动成功",
              type: "success",
            });
            this.get_mk_list();
          });
        })
        .catch(() => {});
    },
  },
  created() {
    this.getproducttype();
    this.get_mk_list();
  },
};
</script>

<style lang="scss" scoped>
.seckill {
  width: 100%;
  height: auto;

  &-head {
    width: 100%;
    height: 120px;
    // background: red;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    &-l,
    &-r {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      //   background: coral;
    }
    &-l {
      justify-content: flex-start;
    }
    &-r {
      justify-content: flex-end;
    }
  }
  &-box {
    padding: 10px;
    box-sizing: border-box;
  }

  &-alert {
    width: 100%;
    height: 100%;
    &-add {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 15px 0;
    }
    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      &-item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
</style>

<style moudle>
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}
@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
</style>
